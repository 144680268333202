<template>
  <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="checkData()"/>
</template>

<script>

  import moduleMember from "@/store/member/moduleMember.js";
  import moduleConfig from "@/store/config/moduleConfig.js";
  import moment from "moment";
  import * as XLSX from "xlsx";

  export default {
    components: {
    },
    name: "RealUserReport",
    data() {
      return {
        orgData: '',
        date: ''
      }
    },
    methods: {
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      warningDialog(message) {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: message,
          acceptText: 'ปิด'
        })
      },
      async checkData() {
        this.$vs.loading();
        this.orgData = await this.$store.state.config.item;
        let name = 'สรุปยอดผู้ใช้น้ำ';
        const payload = {};
        await this.getMember(payload, name);
      },
      async getMember(payload, name) {
        await this.$store.dispatch("member/fetchDataListByFilter", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const member = res.data;
            this.generatePDF(member);
            this.$vs.loading.close();
          } else {
            this.warningDialog('ไม่มีรายชื่อ' + name);
            this.$vs.loading.close();
          }
        }).catch((error) => {
          console.log(error)
          this.warningDialog('ไม่มีรายชื่อ' + name);
          this.$vs.loading.close();
        });
      },
      groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      },
      generatePDF(member) {
        let fileName = 'ยอดผู้ใช้น้ำ.xlsx';
        let ws = XLSX.utils.json_to_sheet(member);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, fileName);
        XLSX.writeFile(wb, fileName);
      }
    },
    async created() {
      if (!moduleMember.isRegistered) {
        this.$store.registerModule('member', moduleMember);
        moduleMember.isRegistered = true;
      }
      if (!moduleConfig.isRegistered) {
        this.$store.registerModule('config', moduleConfig)
        moduleConfig.isRegistered = true
      }
      await this.$store.dispatch("config/fetchDataItem");
    }
  }
</script>

<style scoped>
</style>
