<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-3">
      <vx-card title="บัญชี/รอบบันทึกค่าบริการ">
        <div class="vx-row">
          <div class="vx-col md:w-1/3 sm:w-1/4 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span class="mx-3">เลือกพื้นที่/กลุ่ม</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select :options="areas" :value="areas" :clearable="false" v-model="selectedArea"/>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
    <div id="data-list-list-view" class="vx-col w-full mb-base data-list-container">
      <vs-table :data="[]">
        <template slot="thead">
          <vs-th>ลำดับที่</vs-th>
          <vs-th>ชื่อรายงาน</vs-th>
          <vs-th>พิมพ์รายงาน</vs-th>
        </template>
        <template>
          <vs-tr>
            <vs-td><p class="product-name font-medium">1</p></vs-td>
            <vs-td><p class="product-name font-medium">ผู้ใช้น้ำในทะเบียน</p></vs-td>
            <vs-td class="whitespace-no-wrap">
              <PDFAllReport :status="'02'" :area="selectedArea"/>
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td><p class="product-name font-medium">2</p></vs-td>
            <vs-td><p class="product-name font-medium">ผู้ใช้น้ำจริง</p></vs-td>
            <vs-td class="whitespace-no-wrap">
              <PDFAllReport :status="'01'" :area="selectedArea"/>
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td><p class="product-name font-medium">3</p></vs-td>
            <vs-td><p class="product-name font-medium">ผู้ยกเลิกการใช้น้ำ</p></vs-td>
            <vs-td class="whitespace-no-wrap">
              <PDFAllReport :status="'03'" :area="selectedArea"/>
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td><p class="product-name font-medium">4</p></vs-td>
            <vs-td><p class="product-name font-medium">ผู้ยกเลิกใช้น้ำและมีหนี้ค้างชำระ</p></vs-td>
            <vs-td class="whitespace-no-wrap">
              <PDFAllReport :status="'04'" :area="selectedArea"/>
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td><p class="product-name font-medium">5</p></vs-td>
            <vs-td><p class="product-name font-medium">สรุปผู้ใช้น้ำยกเลิกชั่วคราวไม่มีหนี้ค้าง</p></vs-td>
            <vs-td class="whitespace-no-wrap">
              <PDFAllReport :status="'05'" :area="selectedArea"/>
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td><p class="product-name font-medium">6</p></vs-td>
            <vs-td><p class="product-name font-medium">สรุปผู้ใช้น้ำแต่ละเขต</p></vs-td>
            <vs-td class="whitespace-no-wrap">
              <MemberSummaryReport/>
              <MemberSummaryExcel/>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>

</template>

<script>

  import vSelect from "vue-select";
  import PDFAllReport from '../pdf/AllReportReport.vue';
  import MemberSummaryReport from '../pdf/MemberSummaryReport.vue';
  import MemberSummaryExcel from '../pdf/MemberSummaryExcel.vue';
  import PDFMeterNumber from '../pdf/SaveMeterNumberReport.vue';
  import moduleBillConfig from "@/store/billconfig/moduleBillConfig.js";
  import moduleArea from "@/store/area/moduleArea.js";
  import moduleMember from "@/store/member/moduleMember.js";

  export default {
    components: {
      vSelect,
      PDFAllReport,
      PDFMeterNumber,
      MemberSummaryReport,
      MemberSummaryExcel
    },
    data() {
      return {
        selectedArea: {label: '', value: ''},
        selected: [],
        selectedBillConfig: {label: '', value: ''},
        roleFilter: {label: '1', value: '1'},
        roleOptions: [
          {label: '1', value: '1'},
          {label: '2', value: '2'},
          {label: '3', value: '3'},
          {label: '4', value: '4'}
        ]
      }
    },
    computed: {
      areas() {
        let options = [];
        this.$store.state.area.items.forEach(function (item) {
          options.push({
            label: item.A02,
            value: item.A01
          });
        });
        return options;
      },
      async billConfigs() {
        let options = [];
        this.$store.state.billConfigs.items.forEach(function (item) {
          options.push({
            label: item.CB01  + ' (' + item.CB04+ ')',
            value: item.CB01
          });
        });
        return options;
      },
    },
    methods: {
      async init() {
        const area = await this.$store.state.area.items[0];
        this.selectedArea = {
          label: area.A02,
          value: area.A01
        };
        this.$vs.loading.close();
      }

    },
    async created() {
      this.$vs.loading();
      if(!moduleMember.isRegistered) {
        this.$store.registerModule('member', moduleMember);
        moduleMember.isRegistered = true;
      }
      if (!moduleArea.isRegistered) {
        this.$store.registerModule('area', moduleArea);
        moduleArea.isRegistered = true
      }
      await this.$store.dispatch("area/fetchDataListItems");
      this.init();
    },
  }

  </script>

<style lang="scss">
  #data-list-list-view {
    .vs-con-table {

      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;

          .vs-table--search-input {
            width: 100%;
          }
        }
      }

      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }

      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;

          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }

      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search{
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            &+i {
              left: 1rem;
            }

            &:focus+i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table--not-data {
        display: none;
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;

        tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
</style>
