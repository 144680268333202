<template>
  <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="checkData()"/>
</template>

<script>

  import 'jspdf-autotable'
  import jsPDF from "jspdf";
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moduleMember from "@/store/member/moduleMember.js";
  import moduleConfig from "@/store/config/moduleConfig.js";
  import moment from "moment";

  export default {
    components: {
      jsPDF,
    },
    name: "RealUserReport",
    data() {
      return {
        orgData: '',
        date: ''
      }
    },
    methods: {
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      warningDialog(message) {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: message,
          acceptText: 'ปิด'
        })
      },
      async checkData() {
        this.$vs.loading();
        this.orgData = await this.$store.state.config.item;
        let name = 'สรุปยอดผู้ใช้น้ำ';
        const payload = {};
        await this.getMember(payload, name);
      },
      async getMember(payload, name) {
        await this.$store.dispatch("member/fetchDataListByFilter", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const member = res.data;
            this.generatePDF(member);
            this.$vs.loading.close();
          } else {
            this.warningDialog('ไม่มีรายชื่อ' + name);
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.warningDialog('ไม่มีรายชื่อ' + name);
          this.$vs.loading.close();
        });
      },
      groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      },
      generatePDF(member) {
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: [279.4, 210]
        });
        const orgData = this.orgData;
        const area = this.area;
        const date = this.currentThaiDate();
        let title;
        let header;
        let array = [];
        let areaList = [];
        title = 'สรุปยอดผู้ใช้น้ำ';
        header = ['เขต', 'ผู้ใช้น้ำในทะเบียน', 'ผู้ใช้น้ำจริง', 'ผู้ยกเลิกการใช้น้ำ', 'ผู้ยกเลิกใช้น้ำและมีหนี้ค้างชำระ'];
        let memberList = this.groupBy(member, 'M02');
        Object.keys(memberList).forEach(function (key) {
          const register = memberList[key].length
          const realUser = memberList[key].filter((item) => {
            return item.M23 === '01'
          }).length
          const cancel = memberList[key].filter((item) => {
            return item.M23 === '03'
          }).length
          const cancelHasDebt = memberList[key].filter((item) => {
            return item.M23 === '02'
          }).length
          areaList.push({member_area: key, register, realUser, cancel, cancelHasDebt});
        })
        areaList.sort((a, b) => parseInt(a['member_area']) - parseInt(b['member_area']));
        let register = 0;
        let realUser = 0;
        let cancel = 0;
        let cancelHasDebt = 0;
        areaList.forEach(function (item) {
          array.push([item.member_area,
            item.register,
            item.realUser,
            item.cancel,
            item.cancelHasDebt])
          register += parseFloat(item.register);
          realUser += parseFloat(item.realUser);
          cancel += parseFloat(item.cancel);
          cancelHasDebt += parseFloat(item.cancelHasDebt);
        })

        array.push(['รวมทั้งสิ้น ' + member.length + ' ราย', register+ ' ราย',realUser+ ' ราย',cancel+ ' ราย',cancelHasDebt+ ' ราย'])

        var totalPagesExp = '{total_pages_count_string}';
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.setFont('THSarabunNew', 'bold');
        doc.setFontSize(14);
        doc.autoTable({
          styles: {font: 'THSarabunNew', fontSize: 14},
          margin: {left: 8, top: 33, right: 8},
          theme: 'plain',
          head: [header],
          body: array,
          didDrawPage: function (data) {
            // Header
            doc.setFontSize(18);
            doc.text(orgData.Org01, doc.internal.pageSize.getWidth() / 2, 10, {align: 'center'});
            doc.text(orgData.Org03, doc.internal.pageSize.getWidth() / 2, 16, {align: 'center'});
            doc.setFontSize(16);
            doc.text(title, doc.internal.pageSize.getWidth() / 2, 23, {align: 'center'});
            // Footer
            // doc.setFont('THSarabunNew', 'normal');
            // doc.setFontSize(14);
            // var str = 'Page ' + doc.internal.getNumberOfPages();
            // if (typeof doc.putTotalPages === 'function') {
            //   str = str + ' of ' + totalPagesExp
            // }
            // doc.setFontSize(12);
            // var pageSize = doc.internal.pageSize;
            // var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            // doc.text(moment().format('DD/MM/YYYY'), 10, pageHeight - 10,)
            // doc.text(str, 230, pageHeight - 10, {align: 'right'});
          },
        });
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp);
        }
        const pages = doc.internal.getNumberOfPages();
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        for (let j = 1; j < pages + 1 ; j++) {
          doc.setPage(j);
          doc.addFileToVFS('THSarabunNew-normal.ttf', font);
          doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
          doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
          doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
          doc.setFont('THSarabunNew', 'normal');
          doc.setFontSize(12);
          doc.text('วันที่พิมพ์ ' + date, 10, pageHeight - 8,)
          doc.text(`หน้าที่พิมพ์ ${j} of ${pages}`, 180, pageHeight - 8, {align: 'center'})
        }
        doc.save(title + moment().format('DD-MM-YYYY') + '.pdf');
      }
    },
    async created() {
      if (!moduleMember.isRegistered) {
        this.$store.registerModule('member', moduleMember);
        moduleMember.isRegistered = true;
      }
      if (!moduleConfig.isRegistered) {
        this.$store.registerModule('config', moduleConfig)
        moduleConfig.isRegistered = true
      }
      await this.$store.dispatch("config/fetchDataItem");
    }
  }
</script>

<style scoped>
</style>
