<template>
  <feather-icon icon="PrinterIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="checkData()"/>
</template>

<script>

  import 'jspdf-autotable'
  import jsPDF from "jspdf";
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moduleMember from "@/store/member/moduleMember.js";
  import moduleConfig from "@/store/config/moduleConfig.js";
  import moment from "moment";

  export default {
    props: ['status', 'area'],
    components: {
      jsPDF,
    },
    name: "RealUserReport",
    data() {
      return {
        orgData: '',
        date: ''
      }
    },
    methods: {
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      warningDialog(message) {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: message,
          acceptText: 'ปิด'
        })
      },
      async checkData() {
        this.$vs.loading();
        this.orgData = this.$store.state.config.item;
        let name;
        if (this.status === '01') {
          name = 'ผู้ใช้น้ำจริง';
          const payload = {
            M23: '01',
            M02: this.area.value
          };
          await this.getMember(payload, name);
        } else if (this.status === '02') {
          name = 'ผู้ใช้น้ำในทะเบียน';
          const payload = {
            // M23: this.status,
            M02: this.area.value
          };
          await this.getMember(payload, name);
        } else if (this.status === '03') {
          name = 'ผู้ยกเลิกการใช้น้ำ';
          const payload = {
            M23: '03',
            M02: this.area.value
          };
          await this.getMember(payload, name);
        } else if (this.status === '04') {
          name = 'ผู้ยกเลิกการใช้น้ำและมีหนี้ค้างชำระ';
          const payload = {
            M23: '02',
            M02: this.area.value,
            B10: '0'
          };
          await this.getMemberStatus04(payload, name);
        }else if (this.status === '05') {
          name = 'ผู้ยกเลิกการใช้น้ำไม่มีมีหนี้ค้างชำระ';
          const payload = {
            M23: '03',
            M02: this.area.value,
            B10: '1'
          };
          await this.getMemberStatus04(payload, name);
        }
      },
      async getMember(payload, name) {
        await this.$store.dispatch("member/fetchDataListByFilter", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const member = res.data.sort((a, b) => a['M01'] - b['M01']);
            this.generatePDF(member);
            this.$vs.loading.close();
          } else {
            this.warningDialog('ไม่มีรายชื่อ' + name);
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.warningDialog('ไม่มีรายชื่อ' + name);
          this.$vs.loading.close();
        });
      },
      async getMemberStatus04(payload, name) {
        await this.$store.dispatch("member/getMemberForReport", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const member = res.data.sort((a, b) => a['M01'] - b['M01']);
            this.generatePDF(member);
            this.$vs.loading.close();
          } else {
            this.warningDialog('ไม่มีรายชื่อ' + name);
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.warningDialog('ไม่มีรายชื่อ' + name);
          this.$vs.loading.close();
        });
      },
      generatePDF(member) {
        const doc = new jsPDF({format: [279, 210], unit:'mm', orientation: 'portrait',});
        const orgData = this.orgData;
        const area = this.area;
        const date = this.currentThaiDate();
        let title;
        let header;
        let array = [];
        if (this.status == '01') {
          title = 'รายงานบัญชีผู้ใช้น้ำจริง';
          header = ['ลำดับที่', 'รหัสผู้ใช้น้ำ', 'ชื่อสกุล', 'บ้านเลขที่', 'เลขประจำมิเตอร์'];
          for(let i = 0; i < member.length; i++) {
            array.push([i + 1, member[i].M01, member[i].M04, member[i].M05, member[i].M27]);
          }
          array.push([{ content: 'รวมทั้งสิ้น ' + member.length + ' ราย', colSpan: 5 }])
        } else if (this.status == '02') {
          title = 'รายงานผู้ใช้น้ำในทะเบียน';
          header = ['ลำดับที่', 'รหัสผู้ใช้น้ำ', 'ชื่อสกุล', 'บ้านเลขที่', 'เลขประจำมิเตอร์'];
          for(let i = 0; i < member.length; i++) {
            array.push([i + 1, member[i].M01, member[i].M04, member[i].M05, member[i].M27]);
          }
          array.push([{ content: 'รวมทั้งสิ้น ' + member.length + ' ราย', colSpan: 5 }])
        } else if (this.status == '03') {
          title = 'รายงานบัญชีผู้ยกเลิกการใช้น้ำ';
          header = ['ลำดับที่', 'รหัสผู้ใช้น้ำ', 'ชื่อสกุล', 'บ้านเลขที่', 'วันที่ยกเลิก'];
          for(let i = 0; i < member.length; i++) {
            array.push([i + 1, member[i].M01, member[i].M04, member[i].M05, member[i].M24]);
          }
          array.push([{ content: 'รวมทั้งสิ้น ' + member.length + ' ราย', colSpan: 5 }])
        } else if (this.status == '04') {
          title = 'รายงานบัญชีผู้ยกเลิกการใช้น้ำและมีหนี้ค้างชำระ';
          header = ['ลำดับที่', 'รหัสผู้ใช้น้ำ', 'ชื่อสกุล', 'บ้านเลขที่', 'วันที่ยกเลิก'];
          for(let i = 0; i < member.length; i++) {
            array.push([i + 1, member[i].M01, member[i].M04, member[i].M05, member[i].M24]);
          }
          array.push([{ content: 'รวมทั้งสิ้น ' + member.length + ' ราย', colSpan: 5 }])
        }
        var totalPagesExp = '{total_pages_count_string}';
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.setFont('THSarabunNew', 'bold');
        doc.setFontSize(14);
        doc.text(area.label + ' จำนวน ' + member.length + ' คน', 10, 30);
        doc.autoTable({
          styles: {font: 'THSarabunNew', fontSize: 14},
          margin: {left: 8, top: 33, right: 8},
          theme: 'plain',
          head: [header],
          body: array,
          didDrawPage: function (data) {
            // Header
            doc.setFontSize(18);
            doc.text(orgData.Org01, doc.internal.pageSize.getWidth() / 2, 10, {align: 'center'});
            doc.text(orgData.Org03, doc.internal.pageSize.getWidth() / 2, 16, {align: 'center'});
            doc.setFontSize(16);
            doc.text(title, doc.internal.pageSize.getWidth() / 2, 23, {align: 'center'});
            // Footer
            // doc.setFont('THSarabunNew', 'normal');
            // doc.setFontSize(14);
            // var str = 'Page ' + doc.internal.getNumberOfPages();
            // if (typeof doc.putTotalPages === 'function') {
            //   str = str + ' of ' + totalPagesExp
            // }
            // doc.setFontSize(12);
            // var pageSize = doc.internal.pageSize;
            // var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            // doc.text(moment().format('DD/MM/YYYY'), 10, pageHeight - 10,)
            // doc.text(str, 230, pageHeight - 10, {align: 'right'});
          },
        });
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp);
        }
        const pages = doc.internal.getNumberOfPages();
        const pageSize = doc.internal.pageSize;
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        for (let j = 1; j < pages + 1 ; j++) {
          doc.setPage(j);
          doc.addFileToVFS('THSarabunNew-normal.ttf', font);
          doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
          doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
          doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
          doc.setFont('THSarabunNew', 'normal');
          doc.setFontSize(12);
          doc.text('วันที่พิมพ์ ' + date, 10, pageHeight - 8,)
          doc.text(`หน้าที่พิมพ์ ${j} of ${pages}`, 180, pageHeight - 8, {align: 'center'})
        }
        doc.save(title + moment().format('DD-MM-YYYY') + '.pdf');
      }
    },
    async created() {
      if(!moduleMember.isRegistered) {
        this.$store.registerModule('member', moduleMember);
        moduleMember.isRegistered = true;
      }
      if (!moduleConfig.isRegistered) {
        this.$store.registerModule('config', moduleConfig)
        moduleConfig.isRegistered = true
      }
      await this.$store.dispatch("config/fetchDataItem");
    },
  }
</script>

<style scoped>
</style>
